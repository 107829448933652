<template>
	<div id="website" class="el-content">
		<a-tabs v-model:activeKey="active" @change="getAppSiteList(1, info.limit)">
			<a-tab-pane key="-1" tab="全部"></a-tab-pane>
			<a-tab-pane key="0" tab="审核中"></a-tab-pane>
			<a-tab-pane key="1" tab="已通过"></a-tab-pane>
			<a-tab-pane key="2" tab="已拒绝"></a-tab-pane>
		</a-tabs>
		<a-table :pagination="false" :data-source="info.list" row-key="id" :columns="[
				{ title: 'ID', dataIndex: 'id' },
				{ title: '应用', dataIndex: 'program', slots: { customRender: 'program' } },
				{ title: '审核域名', dataIndex: 'domain_apply' },
				{ title: '正式域名', dataIndex: 'domain' },
				{ title: 'icp', dataIndex: 'icp', slots: { customRender: 'icp' } },
				{ title: '创建时间', dataIndex: 'create_time' },
				{ title: '更新时间', dataIndex: 'update_time' },
				{ title: '状态', dataIndex: 'audit', slots: { customRender: 'audit' } },
				{ title: '操作', dataIndex: 'action', slots: { customRender: 'action' } }
			]"
		>
			<template #program="{record}">
				<div style="display: flex;">
					<img style="width: 60px;height: 60px;" v-if="record.program != null" :src="record.program.logo" alt="" />
					<p v-if="record.program != null">{{ record.program.program_name }}</p>
				</div>
			</template>
			<template #icp="{record}">
				<p v-if="record.icp != null">{{ record.icp.num }}</p>
			</template>
			<template #audit="{record}">
				<a-tag color="#00CCFF" v-if="record.audit == 0">审核中</a-tag>
				<a-tag color="#00CC66" v-if="record.audit == 1">已通过</a-tag>
				<a-tag color="#FF6666" v-if="record.audit == 2">已拒绝</a-tag>
			</template>
			<template #action="{record}">
				<a-button v-if="record.audit == 1 || record.audit == 2" @click="getAppSiteAuditLog(record.id)" type="link" size="small">记录</a-button>
				<a-button v-if="record.audit == 0 || record.audit == 3" @click="examineBranch(record.id, 1)" type="link" size="small">通过</a-button>
				<a-button v-if="record.audit == 0 || record.audit == 3" @click="examineBranch(record.id, 2)" type="link" size="small">拒绝</a-button>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange=" (page, e) => { getWebSiteList(info.page, e); } "
				@change=" e => { getWebSiteList(e, info.limit); } "
			/>
		</div>
		<a-modal v-model:visible="reject" title="驳回申请" @ok="sureRejctCheck" @cancel="reject = false" width="600px">
			<a-textarea v-model:value="rejectForm.reason" placeholder="驳回理由" :rows="6" />
		</a-modal>
		<a-modal v-model:visible="dialogVisible" title="域名审核记录" :footer="null" @cancel="dialogVisible = false" width="700px">
			<a-table :pagination="false" :data-source="datalist" row-key="id" :columns="[
					{ title: 'ID', dataIndex: 'id' },
					{ title: '用户ID', dataIndex: 'user_id' },
					{ title: 'action', dataIndex: 'action' },
					{ title: 're_type', dataIndex: 're_type' },
					{ title: '备注', dataIndex: 'title' },
					{ title: '创建时间', dataIndex: 'create_time' }
				]"
			></a-table>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import programModel from '@/api/saas/app';
export default {
	setup(){
		const state = reactive({
			info: {
				list: [],
				page: 1,
				count: 0,
				limit: 10
			},
			active: '-1',
			reject: false,
			rejectForm: {
				id: 0,
				audit: 3,
				reason: ''
			},
			dialogVisible: false,
			datalist: [],
			arrID: []
		})
		getAppSiteList(1,state.info.limit)

		function getAppSiteList(page,limit){
			let audit = []
			if( state.active == 0 ) audit = [0,3]
			if( state.active == 1 || state.active ==2 ) audit = [state.active]
			programModel.getH5SiteAudit(page,limit,{audit,sence:'h5'},res=>state.info = {limit,...res})
		}

		function examineBranch(id,audit){
			state.rejectForm.audit = audit
			state.rejectForm.id = id == -1 ? state.arrID : [id]
			if( audit == 1 ){
				sureRejctCheck()
				return
			}
			state.reject = true
		}

		function sureRejctCheck(){
			programModel.h5SiteAudit('h5',state.rejectForm,()=>{
				getAppSiteList(state.info.page,state.info.limit)
				state.reject = false
				state.rejectForm.id = 0
				state.rejectForm.reason = ""
			})
		}
		function getAppSiteAuditLog(id){
			programModel.h5SiteAuditLog('h5',id,res=>{
				state.dialogVisible = true
				state.datalist = res.data
			})
		}

		return{
			...toRefs(state),
			getAppSiteList,
			examineBranch,
			sureRejctCheck,
			getAppSiteAuditLog
		}
	}
}
</script>

<style></style>
